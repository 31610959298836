import gql from "graphql-tag";

export const VIEWER_QUERY = gql`
  query ViewerQuery {
    viewer {
      id
      email
      blogId
      card {
        brand
        last4
      }
      subscription {
        id
        status
        periodEnd
        cancelAtPeriodEnd
      }
    }
  }
`;

/**
 * AUTH
 */

export const SIGNUP = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      account {
        id
        email
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      account {
        id
        email
        blogId
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation LogoutMutation {
    logout
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

/**
 * STRIPE
 */

export const UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input)
  }
`;

export const SUBSCRIBE = gql`
  mutation subscribe {
    subscribe
  }
`;

export const UNSUBSCRIBE = gql`
mutation unsubscribe {
  unsubscribe
}
`;

/**
 * BLOG
 */
export const GET_VIEWER_SETTINGS = gql`
  query ViewerSettingsQuery {
    viewer {
      id
      email
      subscription {
        id
        status
        periodEnd
        cancelAtPeriodEnd
      }
      card {
        brand
        last4
      }
      blogId
      blog {
        id
        domain
        slug
      }
    }
  }
`;

export const UPDATE_BLOG = gql`
  mutation updateBlog($input: UpdateBlogInput!) {
    updateBlog(input: $input) {
      blog {
        id
        domain
        slug
        theme {
          colors {
            primary
          }
        }
      }
    }
  }
`;

/**
 * PAGE
 */

export const GET_VIEWER_BUILDER = gql`
  query PageQuery {
    viewer {
      id
      email
      blogId
      blog {
        id
        domain
        slug
        theme {
          colors {
            primary
          }
        }
        pages {
          id
          blogId
          slug
          isPublic
          blocks {
            id
            blockName
            props
          }
        }
      }
    }
  }
`;

export const SAVE_BUILDER = gql`
  mutation SaveBuilderMutation(
    $blogInput: UpdateBlogInput!
    $pageId: String!
    $pageInput: UpdatePageInput!
  ) {
    updateBlog(input: $blogInput) {
      blog {
        id
        domain
        slug
        theme {
          colors {
            primary
          }
        }
      }
    }
    updatePage(id: $pageId, input: $pageInput) {
      page {
        id
        slug
        isPublic
        blocks {
          id
          blockName
          props
        }
      }
    }
  }
`;

export const CREATE_PAGE = gql`
  mutation CreatePageMutation($input: CreatePageInput!) {
    createPage(input: $input) {
      page {
        id
        slug
      }
    }
  }
`;

export const CHANGE_PAGE_PUBLIC = gql`
  mutation ChangePagePublicMutation($id: String!, $isPublic: Boolean!) {
    updatePage(id: $id, input: { isPublic: $isPublic }) {
      page {
        id
        blogId
        slug
        isPublic
      }
    }
  }
`;

/**
 * POST
 */

export const GET_VIEWER_POSTS = gql`
  query ViewerPostsQuery {
    viewer {
      id
      email
      blogId
      blog {
        id
        domain
        slug
        drafts: posts(status: DRAFT) {
          id
          blogId
          status
          createdAt
          title
          body
          slug
        }
        publisheds: posts(status: PUBLISHED) {
          id
          blogId
          status
          createdAt
          title
          body
          slug
        }
      }
    }
  }
`;

export const GET_POST = gql`
  query PostQuery($key: PostQueryInput!) {
    post(key: $key) {
      id
      title
      body
      blogId
      slug
      status
    }
  }
`;

export const CREATE_POST = gql`
  mutation CreatePostMutation($input: CreatePostInput!) {
    createPost(input: $input) {
      post {
        id
      }
    }
  }
`;

export const UPDATE_POST = gql`
  mutation UpdatePostMutation($id: String!, $input: UpdatePostInput!) {
    updatePost(id: $id, input: $input) {
      post {
        id
      }
    }
  }
`;

export const PUBLISH_POST = gql`
  mutation PublishPostMutation($id: String!, $input: PublishPostInput!) {
    publishPost(id: $id, input: $input) {
      post {
        id
      }
    }
  }
`;

/**
 * PUBLIC
 */

export const GET_PUBLIC_PAGE = gql`
  query PublicPageQuery($key: PublicPageQueryInput!) {
    publicPage(key: $key) {
      theme {
        colors {
          primary
        }
      }
      page {
        id
        blogId
        slug
        blocks {
          id
          blockName
          props
        }
      }
    }
  }
`;
